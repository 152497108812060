import React, { Fragment, useEffect } from "react";

// Libraries
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

// Core
import {
	questionsOn,
	getQuestions,
	setAnswer,
	setCompleted,
} from "../../../core/questions";

import { getSession } from "../../../core/session";

// Components
import Loading from "../../components/pages/loading";
import Container from "../../components/container";
import List from "./list";

const Game = ({ questionsOn, setAnswer, setCompleted, list, session }) => {
	useEffect(() => {
		questionsOn();
		// eslint-disable-next-line
	}, []);

	if (!list || !list.length) {
		return <Loading />;
	}

	return (
		<Fragment>
			<Container>
				<List
					list={list}
					completed={session.completed}
					totalLength={list.length}
					setAnswer={setAnswer}
					setCompleted={setCompleted}
				/>
			</Container>
		</Fragment>
	);
};

const mapStateToProps = createSelector(
	getQuestions,
	getSession,
	(list, session) => ({
		list,
		session,
	})
);

export default connect(mapStateToProps, {
	questionsOn,
	setAnswer,
	setCompleted,
})(Game);
