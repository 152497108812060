import React, { Fragment, useState } from "react";

// Components
import Button from "../../components/buttons";

function Open({
	onSubmitAnswer,
	placeholder = "Aanvullingen/opmerkingen over dit thema",
}) {
	const [value, setValue] = useState("");

	const submitAnswer = () => {
		onSubmitAnswer(value);
		setValue("");
	};

	return (
		<Fragment>
			<textarea
				className="textarea mt-m"
				placeholder={placeholder}
				onChange={({ target }) => setValue(target.value)}
				value={value}
			/>

			<Button
				text="Volgende"
				className="mt-m full"
				onClick={submitAnswer}
			></Button>
		</Fragment>
	);
}

export default Open;
